import React from "react"
import BackgroundFooter from "./background/BackgroundFooter"
import { Link } from "gatsby"
import { Facebook, LinkedIn, Instagram, Twitter, Vimeo } from "./Social"
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="background-row-wrapper left less-footer" data-bg-animation="none">
          <div className="inner-wrap box-top-footer">
            <BackgroundFooter />
          </div>
          <div className="row-bg-overlay" />
        </div>
        <div className="row links-row">
          <div className="offset-lg-3 col-lg-2">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2">
            <h5>About Us</h5>
            <ul>
              <li>
                <Link to="/our-story">Our Story</Link>
              </li>
              <li>
                <Link to="/our-mission">Our Mission</Link>
              </li>
              <li>
                <Link to="/our-team"> Our Team</Link>
              </li>
              <li>
                <Link to="/our-customers">Our Customers</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2">
            <h5>Offerings</h5>
            <ul>
              <li>
                <Link to="/touchstone">Touchstone</Link>
              </li>
              <li>
                <Link to="/telco-cloud">Telco Cloud</Link>
              </li>
              <li>
                <Link to="/it-cloud">IT Cloud</Link>
              </li>
              <li>
                <Link to="/edge-cloud">Edge Cloud</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h5>Resources</h5>
            <ul>
              <li>
                <Link to="/news">News &amp; Media</Link>
              </li>
              <li>
                <Link to="/whitepaper">Whitepapers</Link>
              </li>
              <li>
                <Link to="/videos">Videos</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-9 offset-lg-3">
            <ul className="social-link">
              <li>
                <a href="https://www.facebook.com/voereir" target="_blank">
                  <Facebook />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/voereir" target="_blank">
                  <Twitter />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/voereirofficial" target="_blank">
                  <Instagram />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/10368781" target="_blank">
                  <LinkedIn />
                </a>
              </li>
              <li>
                <a href="https://vimeo.com/voereir" target="_blank">
                  <Vimeo />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {currentYear} VoerEir. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
