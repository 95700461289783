import React from 'react'

export const Facebook =()=>(

<svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VoerEir-Website-Final" transform="translate(-626.000000, -5353.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="FOOTER" transform="translate(2.000000, 5014.000000)">
                <g id="facebook" transform="translate(624.000000, 339.000000)">
                    <g id="f_1_">
                        <path d="M5.84225226,16 L5.84225226,8.7021832 L8.49469333,8.7021832 L8.89263769,5.85725059 L5.84225226,5.85725059 L5.84225226,4.04117598 C5.84225226,3.21775956 6.08892861,2.65661117 7.36945593,2.65661117 L9,2.65599237 L9,0.111383985 C8.71802033,0.077556256 7.75008379,0 6.62350575,0 C4.27103117,0 2.66048486,1.32557255 2.66048486,3.75941575 L2.66048486,5.85725059 L0,5.85725059 L0,8.7021832 L2.66048486,8.7021832 L2.66048486,16 L5.84225226,16 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)

export const Twitter =()=>(

    <svg width="16px" height="13px" viewBox="0 0 16 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="VoerEir-Website-Final" transform="translate(-784.000000, -5385.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="FOOTER" transform="translate(2.000000, 5014.000000)">
                    <g id="twitter" transform="translate(782.000000, 371.000000)">
                        <path d="M16,1.539 C15.405,1.8 14.771,1.973 14.11,2.057 C14.79,1.651 15.309,1.013 15.553,0.244 C14.919,0.622 14.219,0.889 13.473,1.038 C12.871,0.397 12.013,0 11.077,0 C9.261,0 7.799,1.474 7.799,3.281 C7.799,3.541 7.821,3.791 7.875,4.029 C5.148,3.896 2.735,2.589 1.114,0.598 C0.831,1.089 0.665,1.651 0.665,2.256 C0.665,3.392 1.25,4.399 2.122,4.982 C1.595,4.972 1.078,4.819 0.64,4.578 C0.64,4.588 0.64,4.601 0.64,4.614 C0.64,6.208 1.777,7.532 3.268,7.837 C3.001,7.91 2.71,7.945 2.408,7.945 C2.198,7.945 1.986,7.933 1.787,7.889 C2.212,9.188 3.418,10.143 4.852,10.174 C3.736,11.047 2.319,11.573 0.785,11.573 C0.516,11.573 0.258,11.561 0,11.528 C1.453,12.465 3.175,13 5.032,13 C11.068,13 14.368,8 14.368,3.666 C14.368,3.521 14.363,3.381 14.356,3.242 C15.007,2.78 15.554,2.203 16,1.539 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)


export const LinkedIn =()=>(


<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VoerEir-Website-Final" transform="translate(-696.000000, -5383.000000)">
            <g id="FOOTER" transform="translate(2.000000, 5014.000000)">
                <g id="linkedin" transform="translate(682.000000, 357.000000)">
                    <g transform="translate(12.000000, 12.000000)" fill="#FFFFFF" fillRule="nonzero" id="Group-11">
                        <g>
                            <path d="M15.995745,16 L15.995745,15.9993303 L16,15.9993303 L16,10.1048947 C16,7.22129551 15.3425956,5 11.772613,5 C10.0564116,5 8.90471278,5.88932181 8.43453033,6.73243638 L8.38488814,6.73243638 L8.38488814,5.26920735 L5,5.26920735 L5,15.9993303 L8.52459545,15.9993303 L8.52459545,10.6861683 C8.52459545,9.28722757 8.80542841,7.93449409 10.6400619,7.93449409 C12.4477468,7.93449409 12.4746954,9.53098746 12.4746954,10.7759041 L12.4746954,16 L15.995745,16 Z" id="Path"></path>
                            <polygon id="Path" points="8.92462238e-17 5 4 5 4 16 0 16"></polygon>
                            <path d="M2,0 C0.895905621,0 0,0.891728544 0,1.99067519 C0,3.08962183 0.895905621,4 2,4 C3.10409438,4 4,3.08962183 4,1.99067519 C3.99930604,0.891728544 3.10340042,0 2,0 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    )

export const Instagram =()=>(


<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="VoerEir-Website-Final" transform="translate(-740.000000, -5383.000000)">
            <g id="FOOTER" transform="translate(2.000000, 5014.000000)">
                <g id="Instagram" transform="translate(726.000000, 357.000000)">
                    <g id="instagram" transform="translate(12.000000, 12.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <path d="M11,0 L5,0 C2.239,0 0,2.239 0,5 L0,11 C0,13.761 2.239,16 5,16 L11,16 C13.761,16 16,13.761 16,11 L16,5 C16,2.239 13.761,0 11,0 Z M14.5,11 C14.5,12.93 12.93,14.5 11,14.5 L5,14.5 C3.07,14.5 1.5,12.93 1.5,11 L1.5,5 C1.5,3.07 3.07,1.5 5,1.5 L11,1.5 C12.93,1.5 14.5,3.07 14.5,5 L14.5,11 Z" id="Shape"></path>
                        <path d="M8,4 C5.791,4 4,5.791 4,8 C4,10.209 5.791,12 8,12 C10.209,12 12,10.209 12,8 C12,5.791 10.209,4 8,4 Z M8,10.5 C6.622,10.5 5.5,9.378 5.5,8 C5.5,6.621 6.622,5.5 8,5.5 C9.378,5.5 10.5,6.621 10.5,8 C10.5,9.378 9.378,10.5 8,10.5 Z" id="Shape"></path>
                        <circle id="Oval" cx="12.3" cy="3.7" r="1"></circle>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
export const Vimeo =()=>(
    <svg width="16px" height="15px" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="VoerEir-Website-Final" transform="translate(-827.000000, -5384.000000)">
                <g id="FOOTER" transform="translate(2.000000, 5014.000000)">
                    <g id="vimeo" transform="translate(814.000000, 357.000000)">
                        <path d="M24.664414,13.503586 C22.4457884,13.4234219 20.9471494,14.6874965 20.1564974,17.2759342 C20.5631565,17.0950682 20.9584826,17.0142416 21.3411421,17.0142416 C22.1537938,17.0142416 22.5131205,17.4760131 22.4171223,18.3935934 C22.3691231,18.9494417 22.0117964,19.7577074 21.3424755,20.8210405 C20.6698212,21.8843737 20.1671639,22.417034 19.8325034,22.417034 C18.8178555,22.417034 18.2078667,17.5912903 18.0012039,16.2735522 C17.7138759,14.4417042 16.94789,13.5850751 15.7032464,13.7049899 C14.5606008,13.8129795 12.5666377,15.8574942 11,17.2434711 L11.7526528,18.21869 C12.4693062,17.7138552 12.8872984,17.4607753 13.0066296,17.4607753 C14.2539398,17.4607753 14.8579287,21.6789947 15.8265774,24.8159931 C16.4765654,26.5471392 17.2558843,27.4130435 18.1952003,27.4130435 C19.7045057,27.4130435 21.5431384,25.9926159 23.7217648,23.1504357 C25.8250593,20.4294954 26.9143724,18.2849413 26.9843712,16.7207484 L26.9930377,16.7141233 C27.0823693,14.6172701 26.3123836,13.5439993 24.664414,13.503586 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
    

    