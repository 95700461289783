import React, { Component } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    activeSubNav2: false,
    ativeSubNav3: false,
    currentPath: false,
    activeClass: "top",
  }

  componentDidMount = () => {
    this.setState({ currentPath: this.props.location.pathname })
    const isBrowser = () => typeof window !== "undefined"
    isBrowser() &&
      window.addEventListener("scroll", () => {
        let activeClass = "normal"
        if (window.scrollY === 0) {
          activeClass = "top"
        }
        this.setState({ activeClass })
      })
  }

  handleMenuToggle = () => {
    if (!this.state.active) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "unset"
    }
    this.setState({ active: !this.state.active })
  }

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav => {
    console.log("clicked");
    this.setState(
      {
        activeSubNav: this.state.activeSubNav === subNav ? false : subNav,
      },
      () => {
        document.addEventListener("click", this.untoggleSubNav)
      }
    )
  }

  untoggleSubNav = () => {
    this.setState({ activeSubNav: false }, () => {
      document.removeEventListener("click", this.untoggleSubNav)
    })
  }
  toggleSubNav2 = subNav => {
    this.setState(
      {
        activeSubNav2: this.state.activeSubNav2 === subNav ? false : subNav,
      },
      () => {
        document.addEventListener("click", this.untoggleSubNav2)
      }
    )
  }

  untoggleSubNav2 = () => {
    this.setState({ activeSubNav2: false }, () => {
      document.removeEventListener("click", this.untoggleSubNav2)
    })
  }

  toggleSubNav3 = subNav => {
    console.log("hovered");
    this.setState(
      {
        activeSubNav3: this.state.activeSubNav3 === subNav ? false : subNav,
      },
      () => {
        document.addEventListener("click", this.untoggleSubNav3)
      }
    )
  }

  untoggleSubNav3 = () => {
    this.setState({ activeSubNav3: false }, () => {
      document.removeEventListener("click", this.untoggleSubNav3)
    })
  }

  toggleSubNav4 = subNav => {
    this.setState(
      {
        activeSubNav4: this.state.activeSubNav4 === subNav ? false : subNav,
      },
      () => {
        document.addEventListener("click", this.untoggleSubNav4)
      }
    )
  }

  untoggleSubNav4 = () => {
    this.setState({ activeSubNav4: false }, () => {
      document.removeEventListener("click", this.untoggleSubNav4)
    })
  }

  render() {
    const { active, activeClass } = this.state,
      { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? "active" : ""
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${activeClass} ${active ? "Nav-active" : ""}`}>
        <div className="Nav--Container container">
          <Link to="/" onClick={this.handleLinkClick}>
            <div className="Logo" />
          </Link>
          <div className="Nav--Links">
            <NavLink to="/">Home</NavLink>
            <div
              className={`Nav--Group ${
                this.state.activeSubNav3 === "demo" ? "active" : ""
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes("our-story") ||
                  this.props.location.pathname.includes("our-mission") ||
                  this.props.location.pathname.includes("our-team") ||
                  this.props.location.pathname.includes("our-customers")
                    ? "active"
                    : ""
                }`}
                onClick={() => this.toggleSubNav3("demo")}
              >
                About Us
              </span>
              <div className="Nav--GroupLinks">
                <NavLink to="/our-story" className="Nav--GroupLink">
                  Our Story
                </NavLink>
                <NavLink to="/our-mission" className="Nav--GroupLink">
                  Our Mission
                </NavLink>
                <NavLink to="/our-team" className="Nav--GroupLink">
                  Our Team
                </NavLink>
                <NavLink to="/our-customers" className="Nav--GroupLink">
                  Our Customers
                </NavLink>
              </div>
            </div>
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === "posts" ? "active" : ""
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes("touchstone") ||
                  this.props.location.pathname.includes("telco-cloud") ||
                  this.props.location.pathname.includes("it-cloud") ||
                  this.props.location.pathname.includes("edge-cloud")
                    ? "active"
                    : ""
                }`}
                onClick={() => this.toggleSubNav("posts")}
              >
                Product & Services
              </span>
              <div className="Nav--GroupLinks Nav--GroupLinksBroader">
                  <div className="row">
                  <div className = "col-md-6">
                      <h5 className="Nav--GroupLink NavHeading">Product</h5>
                      <NavLink to="/touchstone" className="Nav--GroupLink">
                        Touchstone
                      </NavLink>
                    </div>
                    <div className = "col-md-6">
                        <h5 className="Nav--GroupLink NavHeading">Testing Services</h5>
                        <NavLink to="/telco-cloud" className="Nav--GroupLink">
                          Telco Cloud
                        </NavLink>
                        <NavLink to="/it-cloud" className="Nav--GroupLink">
                          IT Cloud
                        </NavLink>
                        <NavLink to="/edge-cloud" className="Nav--GroupLink">
                          Edge Infrastructure
                        </NavLink>
                    </div>
                  </div>
              </div>
            </div>
            <div
              className={`Nav--Group ${
                this.state.activeSubNav2 === "resources" ? "active" : ""
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes("whitepaper") ||
                  this.props.location.pathname.includes("videos")
                    ? "active"
                    : ""
                }`}
                onClick={() => this.toggleSubNav2("resources")}
              >
                Resources
              </span>
              <div className="Nav--GroupLinks">
                <NavLink to="/whitepaper" className="Nav--GroupLink">
                  White Papers
                </NavLink>
                <NavLink to="/videos" className="Nav--GroupLink">
                  Videos
                </NavLink>
              </div>
            </div>
            <NavLink to="/news"> News </NavLink>
            <NavLink to="/careers">Careers</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <div className="toggle-wrap Nav--MenuButton">
            <button
              onClick={this.handleMenuToggle}
              className={`toggle ${active ? "active" : ""}`}
              aria-expanded="false"
            >
              <div className="icon"></div>
              <span className="nav-menu-label" data-close-label="close">
                {active ? "close" : "menu"}
              </span>
            </button>
          </div>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
